import React from "react";
import PropTypes from "prop-types";
import styled from "@emotion/styled";
import Img from "gatsby-image";
import Tilt from "react-tilt";

const Content = styled.div`
  padding-top: 1.5rem;
  padding-bottom: 1.5rem;
  img {
    transition: 0.16s transform cubic-bezier(0.215, 0.61, 0.355, 1) !important;
  }
  img:hover {
    transform: scale(1.02);
  }
`;

const Image = ({ input }) => (
  <Content>
    <Img fluid={input.primary.image.localFile.childImageSharp.fluid} />
  </Content>
);

export default Image;

Image.propTypes = {
  input: PropTypes.object.isRequired
};
