import styled from "@emotion/styled";

const WrapperTwo = styled.div`
  max-width: ${props => props.theme.maxWidth};
  margin: 0 auto;
  padding: 0 10em;
  @media (max-width: ${props => props.theme.breakpoints.l}) {
    padding: 0 6em;
  }
  @media (max-width: ${props => props.theme.breakpoints.m}) {
    padding: 0 4em;
  }
  @media (max-width: ${props => props.theme.breakpoints.s}) {
    padding: 0 2em;
  }
`;

export default WrapperTwo;
