import styled from "@emotion/styled";
import React, { Component, useState } from "react";

const StyledBackground = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background: linear-gradient(
    90deg,
    ${props => props.color1},
    ${props => props.color2}
  );
  pointer-events: none;
  z-index: -10;
  @keyframes AnimationName {
    0% {
      background-position: 0% 50%;
    }
    50% {
      background-position: 100% 50%;
    }
    100% {
      background-position: 0% 50%;
    }
  }
`;
const StyledColorInput = styled.input`
  width: 50px;
  height: 50px;
  min-width: 50px;
  min-height: 50px;
  border: none;
  outline: 0;
  cursor: pointer;
  ::placeholder {
    color: red;
  }
`;
const StyledLabel = styled.label`
  border: 1px solid white;
  position: fixed;
  top: 20px;
  left: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 2em;
  height: 2em;
  border-radius: 50%;
  margin-right: 1em;
  overflow: hidden;
  box-shadow: 0 0.5em 1em rgba(0, 0, 0, 0.3);
  transition: all 0.2s ease;
  :hover {
    transform: translateY(-4px);
    box-shadow: 0 0.5em 1.5em rgba(0, 0, 0, 0.3);
  }
`;
const StyledLabelTwo = styled(StyledLabel)`
  left: auto;
  right: 0;
`;

function Background() {
  const [color1, setColor1] = useState("#4FBAFF"); // '' is the initial state value
  const [color2, setColor2] = useState("#FF4C34"); // '' is the initial state value
  return (
    <>
      <StyledLabel>
        <StyledColorInput
          type="color"
          value={color1}
          onInput={e => setColor1(e.target.value)}
        />
      </StyledLabel>
      <StyledLabelTwo>
        <StyledColorInput
          type="color"
          value={color2}
          onInput={e => setColor2(e.target.value)}
        />
      </StyledLabelTwo>
      <StyledBackground color1={color1} color2={color2} />
    </>
  );
}

export default Background;
