const theme = {
  colors: {
    primary: '#ff8400',
    blue: '#0040FF',
    bg: '#ffffff',
    black: '#000',
    white: '#fff',
    greyLight: '#EBEDF2',
    orangeLight: '#fff1e2',
    orangeLighter: '#ffb882',
    orangeStrong: '#ff903c',
    grey: '#595C62',
    greyLighter: '#bfbfbf',
    greyDark: '#242424',
    greyDarker: '#000000',
  },
  maxWidth: '1300px',
  maxWidthText: 'none',
  breakpoints: {
    xs: '400px',
    s: '500px',
    m: '800px',
    l: '1100px',
  },
}

export default theme
