import styled from "@emotion/styled";

const Title = styled.p`
  font-size: 1.4444rem;
  position: relative;
  color: ${props => props.theme.colors.white};
  padding-left: 0.7em;
  }
`;

export default Title;
