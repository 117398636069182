import React, { Component } from "react";
import PropTypes from "prop-types";
import styled from "@emotion/styled";
import ListItem from "./ListItem";

const Container = styled.div`
  margin-top: 2em;
  margin-bottom: 2em;
  display: flex;
  flex-wrap: wrap;
  transform: translateZ(200px);
  @media (max-width: ${props => props.theme.breakpoints.m}) {
    justify-content: center;
  }
`;
const LoadMore = styled.div`
  margin-top: 2em;
  margin-bottom: 2em;
  width: 100%;
  height: 3em;
  text-align: center;
  padding: 1em;
  cursor: pointer;
  transition: 0.3s all cubic-bezier(0.215, 0.61, 0.355, 1);

  :hover {
    transform: ${props =>
      props.condition ? `translateY(-4px)` : `translateY(4px)`};
  }
  > a {
    box-shadow: 0px -14px 31px -24px rgba(0, 0, 0, 0.5);
    background-color: white;
    padding: 0.2em 0.4em;
    border-radius: 5px;
    color: ${props => props.theme.colors.greyDarker};
  }
`;

export default class IndexListing extends Component {
  constructor(props) {
    super(props);
    this.state = {
      condition: false
    };
    this.handleClick = this.handleClick.bind(this);
  }

  handleClick() {
    this.setState({
      condition: !this.state.condition
    });
  }

  render() {
    const { posts } = this.props;
    return (
      <Container>
        {posts.slice(0, this.state.condition ? 100 : 6).map(post => {
          let categories = false;
          if (post.node.data.categories[0].category) {
            categories = post.node.data.categories.map(
              c => c.category.document[0].data.name
            );
          }
          return (
            <ListItem
              key={post.node.uid}
              node={post.node}
              categories={categories}
            />
          );
        })}
        <LoadMore onClick={this.handleClick} condition={this.state.condition}>
          <a>{this.state.condition ? "Show Less ↑" : "Show More ↓"}</a>
        </LoadMore>
      </Container>
    );
  }
}

IndexListing.propTypes = {
  posts: PropTypes.array.isRequired
};
