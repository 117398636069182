import React, { Component } from "react";
import PropTypes from "prop-types";
import { Link, graphql } from "gatsby";
import styled from "@emotion/styled";
import logo from "../logo.svg";
import logowhite from "../logowhite.svg";

import { Wrapper, WrapperListing, WrapperTwo } from ".";

const StyledHeader = styled.nav`
  padding: 1em 0;
  position: fixed;
  background: ${props =>
    props.invert ? props.theme.colors.primary : props.theme.colors.white};
  width: 100%;
  z-index: 5;
  a:nth-child(1) {
    color: ${props =>
      props.invert ? props.theme.colors.white : props.theme.colors.greyDark};
    font-weight: 400;
    font-style: normal;
    font-family: "Source Sans Pro", -apple-system, "BlinkMacSystemFont",
      "Segoe UI", "Roboto", "Helvetica", "Arial", sans-serif,
      "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
    display: flex;
    align-items: center;
  }
  a:nth-child(2) {
    color: ${props =>
      props.invert ? props.theme.colors.white : props.theme.colors.greyDark};
    font-weight: 400;
    font-style: normal;
    font-family: "Source Sans Pro", -apple-system, "BlinkMacSystemFont",
      "Segoe UI", "Roboto", "Helvetica", "Arial", sans-serif,
      "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
    display: flex;
  }
  ${({ active }) =>
    active &&
    `
    box-shadow: 0px 5px 13px -16px rgba(0, 0, 0, 0.5);

`}
`;
const HeroLink = styled(Link)`
  color: ${props => props.theme.colors.white};
  transition: all 0.2s ease;
  animation: backgroundAnimateOut 0.3s;
  animation-fill-mode: forwards;
  text-decoration: none;
  font-weight: 700;
  padding: 0 0.3em;
  :hover {
    opacity: 0.6;
    color: white;
  }
`;

const Nav = styled.nav`
  display: flex;
  justify-content: space-between;
  padding: 0 0.2em;
  a:first-child div {
    transform: translateX(-1.8em);
  }
  a:first-child:hover div {
    transform: translateX(-1.8em) rotate(360deg);
  }
  @media (max-width: ${props => props.theme.breakpoints.m}) {
    a:first-child div {
      transform: translateX(-1.55em);
    }
    a:first-child:hover div {
      transform: translateX(-1.55em) rotate(360deg);
    }
  }
`;
const Logo = styled.div`
  position: fixed;
  transition: 0.8s all cubic-bezier(0.215, 0.61, 0.355, 1);
  height: 1.1em;
  width: 1.1em;
  transform-origin: 50% 50%;
  backface-visibility: hidden;
  background-image: url(${props => (props.invert ? logowhite : logo)});
`;

class Header extends Component {
  constructor(props) {
    super(props);
    this.state = {
      condition: false
    };
  }

  componentDidMount() {
    window.addEventListener("scroll", this.handleScroll);
  }

  componentWillUnmount() {
    window.removeEventListener("scroll", this.handleScroll);
  }

  handleScroll = () => {
    if (window.scrollY >= 15) {
      this.setState({
        condition: true
      });
    } else {
      this.setState({
        condition: false
      });
    }
  };

  render() {
    const { invert } = this.props;
    return (
      <StyledHeader invert={invert} active={this.state.condition}>
        <WrapperTwo>
          <Nav>
            <HeroLink to="/" aria-label="Back to Home">
              <Logo invert={invert} />
              Andreas Rippl
            </HeroLink>
            <HeroLink to="/contact" aria-label="Contact">
              Contact
            </HeroLink>
          </Nav>
        </WrapperTwo>
      </StyledHeader>
    );
  }
}

export default Header;

Header.propTypes = {
  invert: PropTypes.bool
};

Header.defaultProps = {
  invert: false
};
