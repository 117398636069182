import React, { Component } from "react";
import PropTypes from "prop-types";
import styled from "@emotion/styled";
import { Link } from "gatsby";

const StyledFooter = styled.footer`
  display: flex;
  justify-content: center;
`;

const StyledInnerFooter = styled.div`
  width: 100%;
  max-width: ${props => props.theme.maxWidth};
  margin: 0;
  padding: ${props => (props.invert ? "2em 5em" : "2em 9em")};
  display: flex;
  justify-content: space-between;
  color: ${props =>
    props.invert ? props.theme.colors.white : props.theme.colors.greyDark};
  p {
    margin: 0;
  }
  a {
    color: ${props =>
      props.invert ? props.theme.colors.white : props.theme.colors.greyDark};
  }
  a:hover {
    opacity: 0.6;
  }
  @media (max-width: ${props => props.theme.breakpoints.l}) {
    padding: 2em 3em;
  }
  @media (max-width: ${props => props.theme.breakpoints.s}) {
    padding: 2em 2em;
  }
`;

class Footer extends Component {
  render() {
    const { invert } = this.props;
    return (
      <StyledFooter>
        <StyledInnerFooter invert={invert}>
          © {new Date().getFullYear()}, Andreas Rippl
          <a
            href="https://www.iubenda.com/privacy-policy/65595052"
            class="iubenda-white iubenda-embed"
            title="Privacy Policy "
          >
            Privacy Policy
          </a>
          <Link to="contact">Contact</Link>
        </StyledInnerFooter>
      </StyledFooter>
    );
  }
}

export default Footer;

Footer.propTypes = {
  children: PropTypes.node.isRequired
};
