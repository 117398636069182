import React, { Component } from 'react'
import PropTypes from 'prop-types'
import styled from '@emotion/styled'
import { Link } from 'gatsby'
import Tilt from 'react-tilt'
import Categories from './Categories'

const Project = styled(Link)`
  margin-bottom: 3em;
  padding-left: 1em;
  padding-right: 1em;
  max-width: 500px;
  width: 33%;
  background-color: transparent;
  background-position: center;
  background-size: cover;
  height: 260px;
  animation: fadein 0.8s;
  animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
  @keyframes fadein {
    from {
      transform: scale(0.8) translateY(50px);
      opacity: 0.2;
    }
    to {
      transform: scale(1) translateY(0);
      opacity: 1;
    }
  }

  @media (max-width: ${props => props.theme.breakpoints.l}) {
    width: 50%;
  }
  @media (max-width: ${props => props.theme.breakpoints.m}) {
    width: 100%;
  }
  :hover {
    text-decoration: none;
  }
  img {
    height: 220px;
    width: 100%;
    object-fit: cover;
    transition: 0.3s all cubic-bezier(0.215, 0.61, 0.355, 1);
    box-shadow: 0px 5px 36px -16px rgba(0, 0, 0, 0.3);
    z-index: 1;
    position: relative;
    transform: translateY(20px);
    @media (max-width: ${props => props.theme.breakpoints.m}) {
      transform: scale(1.02) translateY(0px);
      box-shadow: 0px 0px 36px -16px rgba(0, 0, 0, 0.2);
    }
  }
  @media (max-width: ${props => props.theme.breakpoints.m}) {
    margin-bottom: 4em;
  }
`

const OuterWrapper = styled.div`
  position: relative;
  :hover img {
    transform: scale(1.02) translateY(0px) translateZ(20px);
    box-shadow: rgba(39, 44, 49, 0.06) 8px 14px 38px, rgba(39, 44, 49, 0.03) 1px 3px 8px;
  }
  :hover > div {
    transform: translateY(-5px);
    box-shadow: rgba(39, 44, 49, 0.06) 8px 14px 38px, rgba(39, 44, 49, 0.03) 1px 3px 8px;
  }
  @media (max-width: ${props => props.theme.breakpoints.m}) {
    :hover img {
      transform: none;
      transform: scale(1.02) translateZ(20px);
      box-shadow: 0px 5px 20px -16px rgba(0, 0, 0, 0.4);
    }
  }
`

const TextWrapper = styled.div`
  padding: 0.8em;
  background-color: ${props => props.theme.colors.white};
  height: 100%;
  box-shadow: 0px 5px 36px -16px rgba(0, 0, 0, 0.5);
  transition: 0.3s all cubic-bezier(0.215, 0.61, 0.355, 1);
  border-radius: 3px;
  transform: scale(0.98) translateY(-0.5em) translateZ(-10px);
  position: relative;
  @media (max-width: ${props => props.theme.breakpoints.m}) {
    transform: translateY(1px);
    box-shadow: 0px 5px 30px -16px rgba(0, 0, 0, 0.4);
  }
`
const Headline = styled.p`
  font-size: 0.8em;
  color: ${props => props.theme.colors.greyLighter};
  margin-bottom: 0.4em;
  a {
    color: ${props => props.theme.colors.greyLighter};
    font-style: normal;
    font-weight: normal;
  }
  a:hover {
    color: ${props => props.theme.colors.primary};
  }
`

const Title = styled.h3`
  font-size: 1.2em;
  color: ${props => props.theme.colors.greyDark};
  font-weight: 700;
  font-style: normal;
  margin-bottom: 0;
  white-space: nowrap;

  @media (max-width: ${props => props.theme.breakpoints.s}) {
    font-size: 1.777rem;
  }
`

export default class ListItem extends Component {
  render() {
    const { node, categories } = this.props
    return (
      <Project to={node.uid}>
        <Tilt
          className="Tilt"
          options={{ max: 50, scale: 1.02 }}
          style={{ transformStyle: 'preserve-3d', backgroundColor: 'transparent' }}
        >
          <OuterWrapper>
            <img src={node.data.thumbnail.small.url} />
            <TextWrapper>
              <Headline>
                {node.data.date} — {categories && <Categories categories={categories} />}
              </Headline>
              <Title>{node.data.title.text}</Title>
            </TextWrapper>
          </OuterWrapper>
        </Tilt>
      </Project>
    )
  }
}

ListItem.propTypes = {
  node: PropTypes.object.isRequired,
  categories: PropTypes.array.isRequired,
}
