import React, { Component } from 'react'
import PropTypes from 'prop-types'
import styled from '@emotion/styled'
import ListItem from './ListItem'

const Container = styled.div`
  margin-top: 2em;
  margin-bottom: 2em;
  display: flex;
  flex-wrap: wrap;
  transform: translateZ(200px);
  @media (max-width: ${props => props.theme.breakpoints.m}) {
    justify-content: center;
  }
`

export default class Listing extends Component {
  render() {
    const { posts } = this.props
    return (
      <Container>
        {posts.map(post => {
          let categories = false
          if (post.node.data.categories[0].category) {
            categories = post.node.data.categories.map(c => c.category.document[0].data.name)
          }
          return <ListItem key={post.node.uid} node={post.node} categories={categories} />
        })}
      </Container>
    )
  }
}

Listing.propTypes = {
  posts: PropTypes.array.isRequired,
}
