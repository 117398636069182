module.exports = {
  pathPrefix: "/", // Prefix for all links. If you deploy your site to example.com/portfolio your pathPrefix should be "portfolio"
  title: "Andreas Rippl", // Navigation and Site Title
  titleAlt: "Andreas Rippl", // Title for JSONLD
  description: "Developer, Designer & Videographer",
  headline: "Portfolio of Websites, Designs & Videos of Andreas Rippl ", // Headline for schema.org JSONLD
  url: "https://rippl.at", // Domain of your site. No trailing slash!
  siteLanguage: "en", // Language Tag on <html> element
  logo: "/logos/logo.png", // Used for SEO
  ogLanguage: "en_US", // Facebook Language

  // JSONLD / Manifest
  favicon: "src/favicon.png", // Used for manifest favicon generation
  shortName: "Rippl", // shortname for manifest. MUST be shorter than 12 characters
  author: "Andreas Rippl", // Author for schemaORGJSONLD
  themeColor: "#ff7a00",
  backgroundColor: "#fefefe",

  twitter: "@ripplandreas", // Twitter Username
  facebook: "Andi Rippl", // Facebook Site Name
  googleAnalyticsID: "UA-84752175-1",

  skipNavId: "reach-skip-nav" // ID for the "Skip to content" a11y feature
};
